<template>
  <div class="CustomerPageBanner">
    <img
      v-bind:src="imageSrc"
      v-bind:alt="title"
      class="img-fluid"
    />
  </div>
</template>

<script>
export default {
  name: "CustomerPageBanner",
  props: {
    src: {
      type: String,
      required: false,
      default: ""
    }
  },
  computed: {
    imageSrc() {
      let src = this.src || this?.$root?.config?.company_banner || "";
      return src;
    },
    title() {
      return (
        this.$store.getters["dashboard/property"]("company_name") ||
        "HI Tecnologia"
      );
    }
  }
};
</script>

<style>
.CustomerPageBanner {
  margin: 0px 0px;
  text-align: center;
}

.CustomerPageBanner > img {
  width: 36%;
}
</style>
