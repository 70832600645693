var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"selection-container",style:(_vm.panelStyle)},[(_vm.state == 'busy')?_c('div',{staticClass:"text-center",staticStyle:{"padding":"10px"}},[_c('i',{staticClass:"fa fa-refresh fa-spin"})]):_vm._e(),_c('div',{class:{
        'editor-toolbar': _vm.mode == 'editor',
        editing: _vm.isEditing
      }}),_c('div',{staticClass:"container-content",class:_vm.mode == 'editor' ? 'editor' : ''},[(_vm.showBanner)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2"},[_c('CustomerPageBanner',{style:(_vm.banner && _vm.banner.style),attrs:{"src":_vm.banner && _vm.banner.src}})],1)]):_vm._e(),_c('EquipmentSearchQueryCardPanel',{class:_vm.showStatsToolbar
            ? 'cards-panel'
            : 'cards-panel cards-panel-collapsed',attrs:{"source":_vm.source,"config":_vm.statsPanel,"animate":_vm.filterAnimation}}),(_vm.showStringSearch)?_c('div',{staticClass:"row",staticStyle:{"margin-top":"15px"}},[[_c('div',{class:_vm.showNewConnectorButton
                ? 'form-group col-lg-5 col-lg-offset-3 col-md-6 col-md-offset-2 col-sm-8 col-sm-offset-1'
                : 'form-group col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1'},[_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-addon btn btn-collapse-stats",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.showStatsToolbar = !_vm.showStatsToolbar}}},[_c('i',{staticClass:"glyphicon glyphicon-chevron-down collapse-icon",class:_vm.showStatsToolbar ? 'collapse-icon-collapsed' : ''})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.queryString),expression:"queryString"}],ref:"queryString",staticClass:"form-control search-input",attrs:{"type":"text","placeholder":_vm.$t('placeholders.search')},domProps:{"value":(_vm.queryString)},on:{"input":function($event){if($event.target.composing)return;_vm.queryString=$event.target.value}}}),_c('div',{staticClass:"input-group-addon btn",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.resetQuery.apply(null, arguments)}}},[(_vm.queryString)?_c('i',{staticClass:"fa fa-close reset-icon",class:_vm.busy ? 'reset-icon-disabled' : ''}):_c('i',{staticClass:"fa fa-search"})])])]),(_vm.showNewConnectorButton)?_c('div',{staticClass:"form-group col-lg-1 col-md-2 col-sm-2"},[_c('button',{staticClass:"btn btn-primary form-control",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.newEquipment.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$tc("new"))+" ")])]):_vm._e()]],2):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }