<template>
  <section>
    <div class="selection-container" :style="panelStyle">
      <div class="text-center" v-if="state == 'busy'" style="padding: 10px">
        <i class="fa fa-refresh fa-spin"></i>
      </div>
      <div
        :class="{
          'editor-toolbar': mode == 'editor',
          editing: isEditing
        }"
      >
        <!-- <slot name="toolbar"></slot> -->
      </div>

      <div class="container-content" :class="mode == 'editor' ? 'editor' : ''">
        <div class="row" v-if="showBanner">
          <div class="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2">
            <CustomerPageBanner
              :src="banner && banner.src"
              :style="banner && banner.style"
            />
          </div>
        </div>
        <!-- <div>{{ $store.getters["equipment/stats"] }}</div> -->
        <EquipmentSearchQueryCardPanel
          v-bind:source="source"
          :config="statsPanel"
          :class="
            showStatsToolbar
              ? 'cards-panel'
              : 'cards-panel cards-panel-collapsed'
          "
          :animate="filterAnimation"
        />
        <!-- \Active Filter -->
        <div class="row" style="margin-top: 15px" v-if="showStringSearch">
          <template>
            <div
              :class="
                showNewConnectorButton
                  ? 'form-group col-lg-5 col-lg-offset-3 col-md-6 col-md-offset-2 col-sm-8 col-sm-offset-1'
                  : 'form-group col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1'
              "
            >
              <div class="input-group">
                <div
                  class="input-group-addon btn btn-collapse-stats"
                  v-on:click.prevent.stop="showStatsToolbar = !showStatsToolbar"
                >
                  <i
                    class="glyphicon glyphicon-chevron-down collapse-icon"
                    :class="showStatsToolbar ? 'collapse-icon-collapsed' : ''"
                  ></i>
                </div>
                <input
                  type="text"
                  v-model="queryString"
                  class="form-control search-input"
                  v-bind:placeholder="$t('placeholders.search')"
                  ref="queryString"
                />
                <div
                  class="input-group-addon btn"
                  v-on:click.prevent.stop="resetQuery"
                >
                  <i
                    v-if="queryString"
                    class="fa fa-close reset-icon"
                    :class="busy ? 'reset-icon-disabled' : ''"
                  ></i>
                  <i v-else class="fa fa-search"></i>
                </div>
              </div>
            </div>
            <div
              class="form-group col-lg-1 col-md-2 col-sm-2"
              v-if="showNewConnectorButton"
            >
              <button
                class="btn btn-primary form-control"
                v-on:click.prevent.stop="newEquipment"
              >
                {{ $tc("new") }}
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {debounce} from "lodash";
import EquipmentSearchPanelForm from "@/components/control-sidebar/property-editors/equipment-search-panel-form.vue";
import EquipmentSearchQueryCardPanel from "@/components/equipment-search-query-card-panel.vue";
import CustomerPageBanner from "@/components/customer-page-banner.vue";
import {defaultPanelOptions} from "@/components/control-sidebar/property-editors/detail-form-equipment-search.vue";

export default {
  name: "EquipmentSearchPanel",
  props: {
    panel: {
      type: Object,
      required: false,
      default: () => null
    },
    mode: {
      type: String,
      default: "viewer",
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: () => false
    },
    source: {
      type: String,
      default: () => "device", // "device" || "connector"
      required: false
    },
    searchEnabled: {
      type: Boolean,
      required: false,
      default: () => true
    }
  },
  components: {
    EquipmentSearchQueryCardPanel,
    CustomerPageBanner
  },
  data() {
    return {
      filterAnimation: false,
      customPanel: undefined,
      text: "",
      busy: false
    };
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || {};
    },
    sidebar() {
      return (
        this.$store.getters["dashboard/sidebar"] || {
          name: "unknown"
        }
      );
    },
    query: {
      set(value) {
        return this.$store.dispatch("equipment/setQuery", value);
      },
      get() {
        return this.$store.getters["equipment/query"];
      }
    },
    queryString: {
      set(value) {
        this.text = value;
        if (this._pubText) {
          this.busy = true;
          this._pubText(value);
        }
      },
      get() {
        return this.text;
      }
    },
    showStatsToolbar: {
      set(value) {
        let query = this.query || {};
        query.query_show_stats = value;
        this.query = query;
        if (!this.$utils.isMobile()) {
          this.$nextTick(() => this.$refs.queryString.focus());
        }
        this.filterAnimation = true;
      },
      get() {
        return this?.query?.query_show_stats ?? true;
      }
    },
    statsPanel() {
      let config = (
        this.panelOptions &&
        this?.panelOptions.statsPanel ||
        {}
      )
      if (this.$root.isDarkTheme) {
        config.style = config.style || {};
        config.style["background-color"] = "#222d32";
      }
      return config;
    },
    showStringSearch() {
      return (
        (this.searchEnabled && this?.panelOptions?.showStringSearch) || false
      );
    },
    showNewConnectorButton() {
      return (
        (this.showStringSearch && this?.panelOptions?.showNewConnectorButton) ||
        false
      );
    },
    customConfiguration() {
      if (this.mode == "editor") return null;
      return (
        (this?.contract?.portal_data?.search_page || {})[this.$options.name] ||
        null
      );
    },
    screens() {
      return this.$store.getters["dashboard/screens"] || null;
    },
    state() {
      if (
        !this.contract ||
        (this.customConfiguration && this.customPanel === undefined)
      )
        return "busy";
      return "ready";
    },
    panelOptions() {
      if (this.mode == "editor" || this.$route.query.tpl == "draft") {
        return this?.panel?.options || null;
      } else {
        if (this.customConfiguration) {
          return this?.customPanel?.options || null;
        } else {
          return defaultPanelOptions();
        }
      }
    },
    banner() {
      return this?.panelOptions?.banner || {};
    },
    showBanner() {
      return (
        this?.banner?.enabled ||
        this?.$root?.config?.equipment_selection?.show_banner ||
        false
      );
    },
    panelStyle() {
      // since this panel is not a equipament dashboard only overwrite the bg color
      // if (this.mode == "editor") {
      //   return { "padding-top": "20px" };
      // }
      if (!this.customConfiguration) return {};
      return {
        "background-color":
          (this?.customPanel?.style || {})["background-color"] || "transparent"
      };
    }
  },
  watch: {
    isEditing: {
      handler(n) {
        if (n) {
          if (this.sidebar.name != "EquipmentSearchPanelForm") {
            this.$emit("initCustomProperties", {
              panelName: this.panel.name,
              propertyEditor: EquipmentSearchPanelForm
            });
          }
        }
      },
      immediate: true
    },
    showStringSearch: {
      handler(n) {
        if (n && !this.$utils.isMobile()) {
          this.$nextTick(() => {
            if (this.$refs.queryString) {
              this.$refs.queryString.focus();
            }
          });
        }
      },
      immediate: true
    },
    customConfiguration: {
      handler(n) {
        // do not change it while under edition
        if (n) {
          this.updateCustomView();
        }
      },
      deep: true,
      immediate: true
    },
    screens: {
      handler(n) {
        // do not change it while under edition
        if (this.customConfiguration && n) {
          this.updateCustomView();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onEdit() {
      if (this.mode != "editor") return;
      this.$emit("initCustomProperties", {
        panelName: this.panel.name,
        propertyEditor: EquipmentSearchPanelForm
      });
      this.setSideBar();
    },
    setSideBar() {
      if (this.sidebar.name != "EquipmentSearchPanel") {
        this.$root.$emit("controlSidebar:setContent", EquipmentSearchPanelForm);
      }
    },
    focus() {
      this.$nextTick(
        () => this.$refs.queryString && this.$refs.queryString.focus()
      );
    },
    resetQuery() {
      this.text = "";
      this.$store.dispatch("equipment/reset");
      this.$emit("resetQuery");
      this.focus();
    },
    newEquipment() {
      if (this.mode == "editor") return;
      this.$router.push(`/dashboard/edit/connector/0`);
    },
    customTemplate() {
      return (
        this.$store.getters["dashboard/template"](
          this.customConfiguration.screen_id
        ) || null
      );
    },
    setCustomPanel(template) {
      if (!this.customPanel) {
        let panel =
          ((template && template?.panels) || []).find(
            ({name}) => name == this.customConfiguration.panel_name
          ) || null; // important - default null would render the default layout
        this.$set(this, "customPanel", panel);
      }
    },
    async updateCustomView() {
      if (
        this?.customConfiguration &&
        (this.screens || []).some(
          ({id}) => id == this?.customConfiguration.screen_id
        )
      ) {
        let template = this.customTemplate();
        if (template) {
          this.setCustomPanel(template);
        } else {
          await this.$store
            .dispatch(
              "dashboard/fetchTemplate",
              this.customConfiguration.screen_id
            )
            .then((response) => {
              if (response) {
                this.setCustomPanel(response);
              }
            });
        }
      }
    }
  },
  mounted() {
    if (this.$utils.isMobile()) {
      this.showStatsToolbar = false;
    }
  },
  created() {
    this._pubText = debounce(async (text) => {
      let query = {...this.query};
      query.query_string = text;
      this.query = query;
      this.busy = false;
    }, 500);
    this.text = this?.query?.query_string || "";
    this.$root.$on("query:reset", this.resetQuery);
  },
  beforeDestroy() {
    this.$root.$off("query:reset", this.resetQuery);
  }
};
</script>

<style scoped>
.selection-container {
  padding: 0px 0px 5px 0px;
}

.editor-toolbar {
  position: absolute;
  right: 0px;
  width: 100%;
  border-top: 3px solid #3c8dbc;
}

.editor-toolbar.editing {
  border-top-color: #f39c12;
}

.container-content {
  clear: both;
}

.container-content.editor {
  /* margin-top: 25px; */
}

.filter-option {
  margin-right: 15px;
}

.filter-option span {
  vertical-align: top;
  margin-left: 5px;
}

.filter-option:hover {
  cursor: pointer;
  color: #72afd2;
}

.cards-panel {
  overflow: hidden;
  transition: 0.5s max-height ease-in-out;
  max-height: 400px;
}

@media (min-width: 768px) {
  .cards-panel {
    max-height: 260px;
  }
}

@media (min-width: 992px) {
  .cards-panel {
    max-height: 200px;
  }
}

.cards-panel-collapsed {
  max-height: 0px;
}

.collapse-icon {
  transform: rotate(0);
  transition: 0.3s transform ease-out;
}

.collapse-icon-collapsed {
  transform: rotate(180deg);
  transition: 0.3s transform ease-in;
}

.reset-icon {
  text-shadow: 1px 1px 3px #dcdcdc;
  font-size: 120%;
  color: #c9423f;
}

.skin-dark .reset-icon {
  text-shadow: inherit;
  color: #b8c7ce;
}

.reset-icon-disabled {
  opacity: 0.5;
}

input.search-input:not(:placeholder-shown) {
  border-color: #3c8dbc;
}

.btn-collapse-stats {
  color: #777;
}
.skin-dark .btn-collapse-stats {
  color: #b8c7ce;
}
</style>
