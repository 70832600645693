<template>
  <section class="selection-container">
    <div class="row">
      <div
        class="col-xs-12 col-md-6 col-lg-4"
        v-for="(item, index) in filteredList"
        v-bind:key="index"
      >
        <ProcessAreaSelectionCardOption
          v-bind:item="item"
          v-on:click="onProcessAreaClick(item)"
        ></ProcessAreaSelectionCardOption>
      </div>
    </div>
  </section>
</template>

<script>
import ProcessAreaSelectionCardOption from "@/components/processarea-selection-card-option.vue";
export default {
  name: "ProcessAreaSelectionCardPanel",
  props: {
    useGlobalQueryFilters: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {};
  },
  components: {
    ProcessAreaSelectionCardOption
  },
  computed: {
    items() {
      return this.$store.getters["equipment/equipmentProcessAreaList"];
    },
    query: {
      set(value) {
        return this.$store.dispatch("equipment/setQuery", value);
      },
      get() {
        return this.$store.getters["equipment/query"];
      }
    },
    processAreaChecked: {
      set(value) {
        let query = JSON.parse(JSON.stringify(this.query));
        query.query_process_area = query.query_process_area || {};
        query.query_process_area.id = value;
        this.query = query;
      },
      get() {
        return this.query?.query_process_area?.id || "";
      }
    },
    processAreaIdFromEquipmentList() {
      return this.$utils.distinct(
        (this?.$attrs?.equipmentList || []).map(
          ({process_area}) => process_area.id
        )
      );
    },
    filteredList() {
      if (!this.useGlobalQueryFilters) return this.items;
      let lst = this.items;
      if (this?.query?.query_process_area?.id) {
        lst = lst.filter(
          ({id}) => parseInt(this.query.query_process_area.id) == parseInt(id)
        );
      } else {
        if (
          this?.query?.query_connection?.checked != "all" ||
          this?.query.query_alarm
        ) {
          if (this.processAreaIdFromEquipmentList.length) {
            lst = lst.filter(
              ({id}) => this.processAreaIdFromEquipmentList.indexOf(id) >= 0
            );
          } else {
            lst = [];
          }
        }
      }
      return lst;
    }
  },
  methods: {
    onProcessAreaClick(item) {
      this.processAreaChecked = item.id;
      this.$router.push("/dashboard/search/table");
    }
  }
};
</script>

<style scoped>
.selection-container {
  padding: 20px 5px;
  background-color: whitesmoke;
}

.skin-dark .selection-container {
  background-color: #222d32;
}

@media (min-width: 720px) {
  .selection-container {
    padding: 30px;
  }
}
</style>
