<template>
  <div class="panel-type-title">
    <div class="text">
      <slot name="title">{{ $tc(title, 1) }}</slot>
    </div>
    <div class="toolbar">
      <span
        class="clicable"
        :title="$tc(buttonLabel, 1)"
        @click.stop.prevent="$emit('edit')"
      >
        <i :class="buttonIcon"></i>
      </span>
    </div>
  </div>
</template>

<script>
import messages from "@/i18n/dashboard-panels.js";
export default {
  name: "PanelTypeTitle",
  props: {
    title: {
      type: String,
      default: "",
      required: false
    },
    buttonLabel: {
      type: String,
      default: "general",
      required: false
    },
    buttonIcon: {
      type: String,
      default: "fa fa-gear",
      required: false
    }
  },
  i18n: { messages }
};
</script>

<style scoped>
.panel-type-title {
  margin: 0px -15px 0px -15px;
  text-align: left;
  font-weight: bold;
  border-bottom: 1px solid lightgray;
  color: #5e82a2;
  background-color: whitesmoke;
  padding: 5px 10px 0 10px;
  white-space: nowrap;
}

.skin-dark .panel-type-title {
  border-color: #1e282c;
  color: #b8c7ce;
  background-color: #222d32;
}

.panel-type-title > .text {
  display: inline-block;
  width: calc(100% - 22px);
  text-overflow: ellipsis;
  overflow: hidden;
}

.panel-type-title > .toolbar {
  float: right;
}

.panel-type-title > .toolbar > span {
  margin: 0 5px;
}

.skin-dark .toolbar .clicable {
  opacity: 1;
  color: #b8c7ce;
}
.skin-dark .toolbar .clicable:hover {
  opacity: 1;
  color: #fff;
}
</style>
